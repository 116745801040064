import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from 'firebase/auth'
import { auth, actionCodeSettings } from '@/firebase/config'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import router from '@/router'

export const useAuthStore = defineStore('authStore', () => {
    const user = ref(null)

    const init = () => {
        console.log('AuthStore init')
        try {
            onAuthStateChanged(auth, (userDetails) => {
                console.log('AuthStore state changed:', userDetails)
                if (userDetails) {
                    console.log('User details:', userDetails)
                    user.value = userDetails
                } else {
                    user.value = null
                }
            })
        } catch (error) {
            console.error('Error in auth state observer:', error)
            user.value = null
        }
    }

    const loginUser = async (credentials) => {
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                credentials.email,
                credentials.password
            )
            console.log('loginUser successful')
            return userCredential.user
        } catch (error) {
            console.error('Error in loginUser:', error)
            throw error
        }
    }

    const resetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email, actionCodeSettings)
            return true
        } catch (error) {
            console.error('Error in resetPassword:', error)
            throw error.message
        }
    }

    const getIdToken = async () => {
        try {
            return await auth.currentUser.getIdToken(true)
        } catch (error) {
            console.error('Error getting ID token:', error)
            throw error
        }
    }

    const logoutUser = async () => {
        try {
            await signOut(auth)
            router.push('/login')
        } catch (error) {
            console.error('Error in logoutUser:', error)
            throw error
        }
    }

    const getCurrentUser = async () => {
        const currentUser = auth.currentUser
        
        if (!currentUser) {
            return null
        }

        try {
            const idTokenResult = await currentUser.getIdTokenResult()
            currentUser.claims = idTokenResult.claims
            return currentUser
        } catch (error) {
            console.error('Error getting user claims:', error)
            throw error
        }
    }

    return {
        loginUser,
        logoutUser,
        resetPassword,
        init,
        user,
        getIdToken,
        getCurrentUser
    }
})
