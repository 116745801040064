export default {
    nav: {
        manageRestaurant: 'Manage Restaurant',
        signOut: 'Sign out',
        language: 'Language',
        english: 'English',
        chinese: 'Chinese'
    },
    login: {
        signInToYourAccount: 'Sign in to your account',
        enterYourEmail: 'Enter your email',
        accountEmail: 'Account Email',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        signIn: 'Sign in',
        invalidCredentials: 'Invalid email or password',
        pleaseEnterBothEmailAndPassword: 'Please enter both email and password.',   
        errorAccountIncomplete: 'Account setup incomplete. Please contact support.',
        errorRoleNotSet: 'Account role not set. Please contact support.',
        errorGeneric: 'Account error, please contact us.',
        errorMissingCredentials: 'Please enter both email and password.',
        forgotPasswordDescription: 'Type in your email in the field below and we will send you an Email to reset your password.',
        resetPasswordEmailSent: 'Reset Password Email sent',
        backToLogin: 'Back to Login',
        sendResetPasswordEmail: 'Send Reset Password Email'
    },
    common: {
        loading: 'Loading...',
        error: 'Error',
        success: 'Success',
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit',
        delete: 'Delete',
        confirm: 'Confirm',
        back: 'Back',
        title: 'Title',
        subTitle: 'Sub Title',
        changeBackgroundImage: 'Change background image',
        description: 'Description',
        updateSuccessful: 'Update successful',
        changesWillTakeEffectOnYourWebsiteIn15Minutes:
            'The changes will take effect on your website in 15 minutes',
        deleteFailed: 'Delete failed',
        couldNotDeleteGalleryFiles: 'Could not delete gallery files. Please try again.',
        areYouSureYouWantToDelete: 'Are you sure you want to Delete?',
        addSection: 'Add Section',
        selectSectionType: 'Select Section Type',
        confirmation: 'Confirmation'
    },
    areaTitle: {
        webBasicInfoTitle: 'Website Basic Information',
        headerAreaTitle: 'Header Area',
        bodyAreaTitle: 'Body Area',
        footerAreaTitle: 'Footer Area',
        debugAreaTitle: 'Debug Information'
    },
    webBasicInfo: {
        webDomainSection: {
            title: '🔗 Website Domain',
            accessibleAt: 'Your restaurant website is accessible at the following domain:',
            noDomain: 'No domain has been configured for this website yet.'
        },
        websiteThemeSection: {
            title: '🎨 Website Theme',
            selectTheme: 'Please select a theme',
            fullWidth: 'Full Width',
            narrow: 'Narrow',
            desktopView: 'Desktop View',
            mobileView: 'Mobile View',
            save: 'Save'
        }
    },
    sectionType: {
        topNews: '📣 Top News',
        text: '📝 Text',
        news: '📢 News',
        socialNetworks: '🌍 Social Networks',
        reviewPlatforms: '⭐ Review Platforms',
        gallery: '🖼️ Gallery',
        customCode: '💻 Custom Code',
        staging: '🖼️ Hero Section',
        openingHours: '⏰ Opening Hours',
        map: '🗺️ Map',
        specialOffers: '🎉 Special Offers',
        mobileOrderingApp: '📱 Ordering App Download'
    },
    section: {
        fixed: {
            title: 'Fixed Section',
            promotionsDisplayPolicy: 'Promotions Display Policy',
            promotionsDisplayPolicyDescription:
                'We display promotions available to all clients. Promotions with conditions (new clients, coupon codes, or loyalty rewards) are not shown.',
            needToMakeChanges: 'Need to make changes?',
            modifyInRestaurantAdminPanel: 'Modify in Restaurant Admin Panel',
            thisSectionIsNotEditable: 'This section is not editable, but can be changed display order by dragging.'
        },
        gallery: {
            images: 'images',
            imageTitle: 'Image Title',
            deleteFailed: 'Delete Failed',
            deleteFailedDescription: 'Could not delete image. Please try again.',
            galleryTitle: 'Gallery Title',
            addImage: 'Add Image',
            uploadGalleryImage: 'Upload Gallery Image'
        },
        reviewPlatforms: {
            pleaseEnterAValidPlatformURL: 'Please enter a valid {platform} URL'
        },
        socialNetworks: {
            pleaseEnterAValidPlatformURL: 'Please enter a valid {platform} URL'
        }
    },
    uploadImgDialog: {
        imageDimensionTooSmall:
            'Image dimension is too small, the minimal width is {minQualityWidth}px',
        imageDimensionTooSmallHeight:
            'Image dimension is too small, the minimal height is {minQualityHeight}px',
        imageSizeTooBig: 'Image size is too Big, the max size is {maxSizeMB} MB',
        zoomInstructionsPart1: 'Zoom: Use the ',
        zoomInstructionsPart2: ' buttons or scroll your mouse wheel',
        moveInstructions: 'Move: Click and drag the image to adjust its position',
        cropAreaInstructions: 'The blue border shows the crop area',
        cropAndSelectInstructions: 'Click "Crop and Select" when you\'re satisfied',
        howToAdjustYourImage: 'How to Adjust Your Image:',
        chooseFile: 'Choose File',
        cropAndSelect: 'Crop and Select',
        uploading: 'Uploading',
        selectAnImageFromYourDevice: 'Select an image from your device',
        supportedFormats: 'Supported formats',
        maxSize: 'Max size',
        clickToBrowseFiles: 'Click to Browse Files',
        invalidFileType: 'Invalid file type. Please upload a {acceptedExtensions} file.'
    },
    footer: {
        copyright: '© {year} Whatfood Partner Portal. All rights reserved.'
    }
}
