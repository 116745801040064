import { createI18n } from 'vue-i18n'
import en from './locales/en.js'
import zhCN from './locales/zh-CN.js'
import zhTW from './locales/zh-TW.js'

// Load saved language from localStorage or use default (English)
const savedLanguage = localStorage.getItem('language') || 'en'

const i18n = createI18n({
    legacy: false, // Use Composition API
    locale: savedLanguage,
    fallbackLocale: 'en',
    messages: {
        en,
        'zh-CN': zhCN,
        'zh-TW': zhTW
    },
    globalInjection: true, // Add this to make $t globally available
    disableInstantiation: true, // Disable instantiation of v-t directive
    warnHtmlMessage: false // Disable HTML warnings
})

// Function to change language and save preference to localStorage
export function setLanguage(locale) {
    i18n.global.locale.value = locale
    localStorage.setItem('language', locale)
    document.documentElement.setAttribute('lang', locale) // Set language attribute on html tag
}

export default i18n 