import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { auth } from '@/firebase/config'

// import pinia
import { createPinia } from 'pinia'

// import i18n
import i18n from './i18n'

// import my own global css
import '@/assets/globalStyle.css'
// '@/index.css' is tailwind
import '@/index.css'
import 'flowbite'
/**
 * PrimeVue is the https://primevue.org/ UI Components
 * Load Primevue CSS at the end to avoid Tailwind overwriting it.
 */
import 'primevue/resources/themes/lara-light-teal/theme.css'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'

import Button from 'primevue/button'
import Image from 'primevue/image'
import InputText from 'primevue/inputtext'
import OrderList from 'primevue/orderlist'
import Panel from 'primevue/panel'
import Toast from 'primevue/toast'
import FileUpload from 'primevue/fileupload'
import Textarea from 'primevue/textarea'
import Chips from 'primevue/chips'
import Fieldset from 'primevue/fieldset'
import Avatar from 'primevue/avatar'
import Dialog from 'primevue/dialog'
import Galleria from 'primevue/galleria'
import ProgressBar from 'primevue/progressbar'
import Badge from 'primevue/badge'
import Message from 'primevue/message'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import SpeedDial from 'primevue/speeddial'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import RadioButton from 'primevue/radiobutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import SelectButton from 'primevue/selectbutton';

// primevue import end

/**
 * import Cropper image lib
 */
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
/**
 * import Cropper image lib End
 */

//init app
let app

// onAuthStateChanged(auth, (userDetails) => {
//     app.mount('#app')
// })
auth.onAuthStateChanged((user) => {
    console.log('Auth state changed:', user)
    if (!app) {
        console.log('Creating app...')
        app = createApp(App)
        app.use(createPinia())
        // onAuthStateChanged(auth, (userDetails) => {
        app.use(router)
        
        // Add i18n to the application
        app.use(i18n)

        /**
         * PrimeVue from
         */
        app.use(PrimeVue)
        app.use(ToastService)
        app.use(ConfirmationService)

        app.directive('tooltip', Tooltip)

        app.component('Button', Button)
        app.component('Image', Image)
        app.component('InputText', InputText)
        app.component('OrderList', OrderList)
        app.component('Panel', Panel)
        app.component('Toast', Toast)
        app.component('FileUpload', FileUpload)
        app.component('Textarea', Textarea)
        app.component('Chips', Chips)
        app.component('Fieldset', Fieldset)
        app.component('Avatar', Avatar)
        app.component('Dialog', Dialog)
        app.component('Galleria', Galleria)
        app.component('ProgressBar', ProgressBar)
        app.component('Badge', Badge)
        app.component('Message', Message)
        app.component('Dropdown', Dropdown)
        app.component('DataTable', DataTable)
        app.component('Column', Column)
        app.component('Tag', Tag)
        app.component('SpeedDial', SpeedDial)
        app.component('ConfirmDialog', ConfirmDialog)
        app.component('RadioButton', RadioButton)
        app.component('TabView', TabView)
        app.component('TabPanel', TabPanel)
        app.component('SelectButton', SelectButton)

        /**
         * PrimeVue from end
         */
        //import Cropper image lib
        app.component('Cropper', Cropper)
        //import Cropper image lib end

        console.log('Mounting app...')
        app.mount('#app')
    }
})
