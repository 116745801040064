export default {
    nav: {
        manageRestaurant: '管理餐廳',
        signOut: '登出',
        language: '語言',
        english: '英文',
        chinese: '簡體中文',
        traditionalChinese: '繁體中文'
    },
    login: {
        signInToYourAccount: '登入您的帳戶',
        enterYourEmail: '請輸入您的電子郵件',
        accountEmail: '帳號電子郵件',
        password: '密碼',
        forgotPassword: '忘記密碼？',
        signIn: '登入',
        invalidCredentials: '無效的電子郵件或密碼',
        pleaseEnterBothEmailAndPassword: '請輸入您的電子郵件和密碼。',
        errorAccountIncomplete: '帳號設置不完整。請聯繫支持。',
        errorRoleNotSet: '帳號角色未設置。請聯繫支持。',
        errorGeneric: '帳號錯誤，請聯繫我們。',
        errorMissingCredentials: '請輸入您的電子郵件和密碼。',
        forgotPasswordDescription: '請在下方輸入您的電子郵件，我們將會發送一封電子郵件給您以重置您的密碼。',
        resetPasswordEmailSent: '重置密碼電子郵件已發送。',
        backToLogin: '返回登入',
        sendResetPasswordEmail: '發送重置密碼電子郵件'
    },
    common: {
        loading: '載入中...',
        error: '錯誤',
        success: '成功',
        save: '儲存',
        cancel: '取消',
        edit: '編輯',
        delete: '刪除',
        confirm: '確認',
        back: '返回',
        title: '標題',
        subTitle: '副標題',
        changeBackgroundImage: '更改背景圖片',
        description: '描述',
        updateSuccessful: '更新成功！',
        changesWillTakeEffectOnYourWebsiteIn15Minutes: '更改將於15分鐘內在您的網站生效。',
        deleteFailed: '刪除失敗',
        couldNotDeleteGalleryFiles: '無法刪除圖庫檔案，請再試一次。',
        areYouSureYouWantToDelete: '你確定要刪除嗎？',
        confirmation: '確認',
        addSection: '新增版塊',
        selectSectionType: '請選擇版塊類型'
    },
    areaTitle: {
        webBasicInfoTitle: '網站基本資料',
        headerAreaTitle: '頁首區域',
        bodyAreaTitle: '主體區域',
        footerAreaTitle: '頁尾區域',
        debugAreaTitle: '除錯資訊'
    },
    webBasicInfo: {
        webDomainSection: {
            title: '🔗 網站域名',
            accessibleAt: '您的餐廳網站可透過以下域名存取：',
            noDomain: '此網站尚未設定域名。'
        },
        websiteThemeSection: {
            title: '🎨 網站主題',
            selectTheme: '請選擇主題',
            fullWidth: '全寬',
            narrow: '窄寬',
            desktopView: '桌面檢視',
            mobileView: '流動檢視',
            save: '儲存'
        }
    },
    sectionType: {
        topNews: '📣 頂部新聞',
        text: '📝 文字',
        news: '📢 新聞',
        socialNetworks: '🌍 社交網絡',
        reviewPlatforms: '⭐ 評分平台',
        gallery: '🖼️ 圖庫',
        customCode: '💻 自訂程式碼',
        staging: '🖼️ 主視覺區',
        openingHours: '⏰ 營業時間',
        map: '🗺️ 地圖',
        specialOffers: '🎉 特別優惠',
        mobileOrderingApp: '📱 應用程式下載'
    },
    section: {
        fixed: {
            title: '固定區域',
            promotionsDisplayPolicy: '推廣顯示政策',
            promotionsDisplayPolicyDescription:
                '我們會顯示所有顧客適用的推廣活動。有條件限制的推廣（例如新客、優惠碼或獎賞計劃）不會顯示。',
            needToMakeChanges: '需要修改？',
            modifyInRestaurantAdminPanel: '請於餐廳管理後台修改',
            thisSectionIsNotEditable: '此區域不可編輯，但可透過拖曳調整顯示次序。'
        },
        gallery: {
            images: '圖片',
            imageTitle: '圖片標題',
            deleteFailed: '刪除失敗',
            deleteFailedDescription: '無法刪除圖片，請再試一次。',
            galleryTitle: '圖庫標題',
            addImage: '新增圖片',
            uploadGalleryImage: '上傳圖庫圖片'
        },
        reviewPlatforms: {
            pleaseEnterAValidPlatformURL: '請輸入有效的 {platform} 網址'
        },
        socialNetworks: {
            pleaseEnterAValidPlatformURL: '請輸入有效的 {platform} 網址'
        }
    },
    uploadImgDialog: {
        imageDimensionTooSmall: '圖片寬度過小，最小寬度需為 {minQualityWidth} 像素',
        imageDimensionTooSmallHeight: '圖片高度過小，最小高度需為 {minQualityHeight} 像素',
        imageSizeTooBig: '圖片檔案過大，最大容量為 {maxSizeMB} MB',
        zoomInstructionsPart1: '縮放：使用',
        zoomInstructionsPart2: '按鈕或滑鼠滾輪',
        moveInstructions: '移動：點擊並拖曳圖片調整位置',
        cropAreaInstructions: '藍色邊框顯示裁剪範圍',
        cropAndSelectInstructions: '確認後按「裁剪並選擇」',
        howToAdjustYourImage: '調整圖片指引：',
        chooseFile: '選擇檔案',
        cropAndSelect: '裁剪並選擇',
        uploading: '上傳中',
        selectAnImageFromYourDevice: '從裝置選擇圖片',
        supportedFormats: '支援格式',
        maxSize: '最大容量',
        clickToBrowseFiles: '按此瀏覽檔案',
        invalidFileType: '檔案格式錯誤，請上傳 {acceptedExtensions} 檔案。'
    },
    footer: {
        copyright: '© {year} Whatfood 合作夥伴平台。版權所有。'
    }
}
