<script setup>
    import { onBeforeMount } from 'vue'
    import { useLanguageStore } from '@/stores/LanguageStore'
    
    const languageStore = useLanguageStore()

    onBeforeMount(() => {
        // Initialize language from localStorage
        languageStore.initializeLanguage()
    })
</script>

<template>
    <router-view />
</template>
