export default {
    nav: {
        manageRestaurant: '管理餐厅',
        signOut: '退出登录',
        language: '语言',
        english: '英文',
        chinese: '简体中文',
        traditionalChinese: '繁体中文'
    },
    login: {
        signInToYourAccount: '登录您的账户',
        enterYourEmail: '输入您的电子邮件',
        accountEmail: '账户电子邮件',
        password: '密码',
        forgotPassword: '忘记密码？',
        signIn: '登录',
        invalidCredentials: '无效的电子邮件或密码',
        pleaseEnterBothEmailAndPassword: '请输入您的电子邮件和密码。',   
        errorAccountIncomplete: '账户设置不完整。请联系支持。',
        errorRoleNotSet: '账户角色未设置。请联系支持。',
        errorGeneric: '账户错误，请联系我们。',
        errorMissingCredentials: '请输入您的电子邮件和密码。',
        forgotPasswordDescription: '请在下方输入您的电子邮件，我们将发送一封电子邮件给您以重置您的密码。',
        resetPasswordEmailSent: '重置密码电子邮件已发送。',
        backToLogin: '返回登录',
        sendResetPasswordEmail: '发送重置密码电子邮件'
    },
    common: {
        loading: '加载中...',
        error: '错误',
        success: '成功',
        save: '保存',
        cancel: '取消',
        edit: '编辑',
        delete: '删除',
        confirm: '确认',
        back: '返回',
        title: '标题',
        subTitle: '副标题',
        changeBackgroundImage: '更改背景图片',
        description: '描述',
        updateSuccessful: '更新成功！',
        changesWillTakeEffectOnYourWebsiteIn15Minutes: '更改将在15分钟内在您的网站上生效。',
        deleteFailed: '删除失败',
        couldNotDeleteGalleryFiles: '无法删除图库文件。请重试。',
        areYouSureYouWantToDelete: '您确定要删除吗？',
        confirmation: '确认',
        addSection: '创建新版块',
        selectSectionType: '请选择版块类型'
    },
    areaTitle: {
        webBasicInfoTitle: '网站基本信息',
        headerAreaTitle: '页头区域',
        bodyAreaTitle: '主体区域',
        footerAreaTitle: '页脚区域',
        debugAreaTitle: '调试信息'
    },
    webBasicInfo: {
        webDomainSection: {
            title: '🔗 网站域名',
            accessibleAt: '您的餐厅网站可通过以下域名访问：',
            noDomain: '此网站尚未配置域名。'
        },
        websiteThemeSection: {
            title: '🎨 网站主题',
            selectTheme: '请选择一个主题',
            fullWidth: '全宽',
            narrow: '窄宽',
            desktopView: '桌面视图',
            mobileView: '移动视图',
            save: '保存'
        }
    },
    sectionType: {
        topNews: '📣 顶部新闻',
        text: '📝 文本',
        news: '📢 新闻',
        socialNetworks: '🌍 社交网络',
        reviewPlatforms: '⭐ 评价平台',
        gallery: '🖼️ 图库',
        customCode: '💻 自定义代码',
        staging: '🖼️ 主视觉区',
        openingHours: '⏰ 营业时间',
        map: '🗺️ 地图',
        specialOffers: '🎉 促销',
        mobileOrderingApp: '📱 应用下载'
    },
    section: {
        fixed: {
            title: '固定区域',
            promotionsDisplayPolicy: '促销显示策略',
            promotionsDisplayPolicyDescription:
                '我们显示所有客户可用的促销活动。促销活动有条件（新客户、优惠券代码或忠诚度奖励）不显示。',
            needToMakeChanges: '需要进行更改？',
            modifyInRestaurantAdminPanel: '在餐厅管理员面板中修改',
            thisSectionIsNotEditable: '此区域不可编辑，但是可以通过拖拽改变显示顺序。'
        },
        gallery: {
            images: '图片',
            imageTitle: '图片标题',
            deleteFailed: '删除失败',
            deleteFailedDescription: '无法删除图片。请重试。',
            galleryTitle: '图库标题',
            addImage: '添加图片',
            uploadGalleryImage: '上传图库图片'
        },
        reviewPlatforms: {
            pleaseEnterAValidPlatformURL: '请输入一个有效的 {platform} URL'
        },
        socialNetworks: {
            pleaseEnterAValidPlatformURL: '请输入一个有效的 {platform} URL'
        }
    },
    uploadImgDialog: {
        imageDimensionTooSmall: '图片宽度过小，最小宽度为 {minQualityWidth} 像素',
        imageDimensionTooSmallHeight: '图片高度过小，最小高度为 {minQualityHeight} 像素',
        imageSizeTooBig: '图片尺寸过大，最大尺寸为 {maxSizeMB} MB',
        zoomInstructionsPart1: '缩放：使用',
        zoomInstructionsPart2: '按钮或滚动鼠标滚轮',
        moveInstructions: '移动：点击并拖动图像以调整其位置',
        cropAreaInstructions: '蓝色边框显示裁剪区域',
        cropAndSelectInstructions: '满意后点击"裁剪并选择"',
        howToAdjustYourImage: '如何调整您的图片：',
        chooseFile: '选择文件',
        cropAndSelect: '裁剪并选择',
        uploading: '上传中',
        selectAnImageFromYourDevice: '从您的设备选择一张图片',
        supportedFormats: '支持的格式',
        maxSize: '最大尺寸',
        clickToBrowseFiles: '点击浏览文件',
        invalidFileType: '无效的文件类型。请上传一个 {acceptedExtensions} 文件。'
    },
    footer: {
        copyright: '© {year} Whatfood 合作伙伴门户. 版权所有.'
    }
}
