import { defineStore } from 'pinia'
import { ref } from 'vue'
import { setLanguage } from '@/i18n'

export const useLanguageStore = defineStore('language', () => {
    // Check if user previously had 'zh' locale and migrate to 'zh-CN'
    let savedLang = localStorage.getItem('language')
    if (savedLang === 'zh') {
        localStorage.setItem('language', 'zh-CN')
        savedLang = 'zh-CN'
    }
    
    const currentLanguage = ref(savedLang || 'en')

    // Change language and save to localStorage
    function changeLanguage(langCode) {
        currentLanguage.value = langCode
        setLanguage(langCode)
    }

    // Initialize language from localStorage on app start
    function initializeLanguage() {
        const savedLang = localStorage.getItem('language')
        if (savedLang) {
            // If saved language is 'zh', update it to 'zh-CN'
            if (savedLang === 'zh') {
                changeLanguage('zh-CN')
            } else {
                changeLanguage(savedLang)
            }
        }
    }

    return {
        currentLanguage,
        changeLanguage,
        initializeLanguage
    }
}) 